import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import ContactForm from '@beelineloans/cx-library/src/components/forms/ContactForm';
import { H2, H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import Welcome from '@beelineloans/cx-library/src/images/welcome.png';
import { DeskBanner, TextAndImage } from '@beelineloans/cx-library/src/components/layout/panels';
import { getTry } from '@beelineloans/cx-library/src/utils/fetch';
import ArrowImg from '@beelineloans/cx-library/src/images/icons/arrow-up-dark.png';
import { Button } from '../../../../shared/GatsbyButton';

const ContainerStyled = styled(Container)`
	.container-inner {
		padding-top: 0;
	}
`;

const H2Styled = styled(H2)`
	padding: 100px 0 50px 0;
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		padding: 150px 0 50px 0;
	}
`;

const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 100%;
	grid-template-areas: ".";
	grid-column-gap: 30px;
	grid-row-gap: 20px;
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		grid-template-columns: calc(50% - 15px) calc(50% - 15px);
		grid-template-areas: ". .";
		grid-row-gap: 35px;
	}
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		grid-row-gap: 35px;
	}
`;

const TextAndImageIntro = styled(TextAndImage)`
  border-bottom: 1px solid ${props => props.theme.colours.border.default};
`;

const H3Styled = styled(H3)`
  font-weight: 400;
  margin: 10px 0 10px;
`;

const ParagraphStyled = styled(Paragraph)`
  margin-top: 0;
  padding: 10px 0 20px 0;
`;

const PositionsHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 40px;
`;

const Arrow = styled.img`
  width: 22px;
  height: 13px;
  align-self: center;  
  transform: rotate(${props => (props.$open ? '0' : '180deg')});
`;

const JobList = styled.div`
  ul {
    padding-left: 0;
    max-height: ${props => (props.$open ? `${props.$height + 10}px` : '0')};
    opacity: ${props => (props.$open ? '1' : '0')};
    overflow: hidden;
    transition: all 0.5s ease-out;
    transition-delay: 0s;

    li {
      display: flex;
      flex-direction: row;
      padding-bottom: 36px;

      a {
        flex-basis: 350px;
        color: ${props => props.theme.colours.text.dark} !important;
      }
      span {
        padding-left: 15px;
      }
    
      @media only screen and (${props => props.theme.mediaBreakpoints.small}) {
        flex-direction: column;
        padding-bottom: 15px; 
        a {
          flex-basis: auto; 
        }
        span {
          padding-left: 0;
        }
      }
    }
  }
`;

const Value = ({ heading, children }) => (
  <div>
    <H3Styled>{heading}</H3Styled>
    <ParagraphStyled>{children}</ParagraphStyled>
  </div>
);

Value.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node
};

Value.defaultProps = {};

const Careers = () => {
  const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTry('https://beelineloansinc.bamboohr.com/jobs/embed2.php')
      .then(response => {
        if (response.error) {
          console.error(response.error);
        } else if (document) {
          let tmpDoc = document.implementation.createHTMLDocument('New Document');
          const tmpDiv = tmpDoc.createElement('div');
          tmpDiv.innerHTML = response;
          tmpDoc.body.appendChild(tmpDiv);

          const jobLIs = [...tmpDoc.querySelectorAll('.BambooHR-ATS-Jobs-Item')];
          const jobsHTML = jobLIs.map(j => j.outerHTML.replace(/<a/g, '<a target="_blank" '));
          setCount(jobLIs.length);
          if (jobLIs.length > 0) {
            setJobs(`<ul>${jobsHTML.join().replace(/li>,<li/g, 'li><li')}</ul>`);
            setTimeout(() => { setOpen(true); }, 200);
            tmpDoc = null;
          }
        }
      });
  }, []);

  const headerClicked = () => {
    setOpen(!open);
  };

  return (
    <NavOnlyTemplate>
      <SEO
        path={CONST.LINKS.MAIN.CAREERS.DEFAULT}
        title="Is Beeline the next step in your career?"
        description="We're about great talent — whether we have a spot or not."
      />
      <TextAndImageIntro
        image={{
          src: Welcome,
          mobileWidth: '315px',
          desktopWidth: '445px'
        }}
        heading="We’re engaged in a David and Goliath battle to make an impact in the US mortgage industry."
      >
        <Paragraph>
          The good news is, Goliath is aging and slow — but man, he is very big. He’s enormous..
        </Paragraph>
        <Paragraph>
          It’s no walk in the park. So you need the stomach, patience and talent
          to take on this audacious challenge.
        </Paragraph>
        <Paragraph>
          Beeline is, and always will be a collaborative, non-hierarchical place
          where ideas are more important than who comes up with them, action
          beats talk and candor helps achieve the best outcomes.
        </Paragraph>
      </TextAndImageIntro>
      <Container>
        <PositionsHeaderWrap onClick={headerClicked}>
          <H2>Open positions</H2>
          {jobs && (<Arrow src={ArrowImg} $open={open} />)}
        </PositionsHeaderWrap>
        {jobs && (
          <JobList dangerouslySetInnerHTML={{ __html: jobs }} $open={open} $height={count * 56} />
        )}
        {!jobs && (
          <Button to="https://beelineloansinc.bamboohr.com/jobs/" newWindow>View open positions</Button>
        )}
      </Container>
      <DeskBanner />
      <ContainerStyled backgroundColor={Theme.colours.background.nude}>
        <H2Styled>How we do it: the Beeline values </H2Styled>
        <Grid>
          <Value heading="Curiosity.">
            Innovate. Optimize. Be paranoid, hungry, tenacious.
          </Value>
          <Value heading="Resolutive.">
            Take initiative. Don’t half-ass it. See it through.
          </Value>
          <Value heading="Humility.">
            Do great work without ego or drama. Flow, not force.
          </Value>
          <Value heading="Boldness.">
            Action beats talk. Take smart risks. Be decisive. Foot faults are ok. Just measure and learn.
          </Value>
          <Value heading="Empathy.">
            The customer, not a dollar sign, is the core of every decision.
          </Value>
        </Grid>
      </ContainerStyled>
      <ContactForm
        contactType={CONST.CONTACT_TYPES.CAREERS}
        title="Get in touch."
      >
        <Paragraph>
          Got what it takes but can’t see a role specific to you?<br />
          We’re about great talent — whether we have a spot or not.
        </Paragraph>
      </ContactForm>
    </NavOnlyTemplate>
  );
};

export default Careers;
